import React from "react";
import Plausible from "plausible-tracker";

const { trackEvent } = Plausible();

export function Navbar({ isTouch }) {
  return (
    <>
      {!isTouch && (
        <table border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr valign="top">
              {!isTouch && (
                <td>
                  <map name="11c7b639f00ca9">
                    <area
                      shape="rect"
                      coords="0,0,67,21"
                      href="https://paulgrahamai.com/"
                      alt="home"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,21,67,42"
                      href="https://paulgrahamai.com/"
                      alt="home"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,42,67,63"
                      href="http://www.amazon.com/gp/product/0596006624"
                      alt="amazon"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,63,67,84"
                      href="http://paulgraham.com/books.html"
                      alt="books"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,84,67,105"
                      href="http://ycombinator.com"
                      alt="YC"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,105,67,126"
                      href="http://paulgraham.com/arc.html"
                      alt="arc"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,126,67,147"
                      href="http://paulgraham.com/bel.html"
                      alt="bel"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,147,67,168"
                      href="http://paulgraham.com/lisp.html"
                      alt="lisp"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,168,67,189"
                      href="http://paulgraham.com/antispam.html"
                      alt="spam"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,189,67,210"
                      href="http://paulgraham.com/kedrosky.html"
                      alt="kedrosky"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,210,67,231"
                      href="http://paulgraham.com/faq.html"
                      alt="faq"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,231,67,252"
                      href="http://paulgraham.com/raq.html"
                      alt="raq"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,252,67,273"
                      href="http://paulgraham.com/quo.html"
                      alt="quo"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,273,67,294"
                      href="http://paulgraham.com/rss.html"
                      alt="rss"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,294,67,315"
                      href="http://paulgraham.com/bio.html"
                      alt="bio"
                    ></area>
                    <area
                      shape="rect"
                      coords="0,315,67,336"
                      href="https://twitter.com/paulgrahamai"
                      alt="twitter"
                    ></area>
                  </map>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src="https://s.turbifycdn.com/aah/paulgraham/img-24.gif"
                      width="69"
                      height="399"
                      usemap="#11c7b639f00ca9"
                      border="0"
                      hspace="0"
                      vspace="0"
                      ismap=""
                      alt="quotes"
                    />
                  </div>
                </td>
              )}
              <td>
                <div
                  style={{
                    height: "1px",
                    width: "26px",
                  }}
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
}
