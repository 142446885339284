import React, { useEffect, useState } from "react";
import axios from "axios";
import { handleUpvote } from "./handleUpvote";
import { ImageTitle } from "./ImageTitle";
import { GrayOutNumbers } from "./GrayOutNumbers";
import { useParams } from "react-router-dom";
import { baseUrl } from "./routing";
import { defaultEssay, defaultEssayTitle, defaultEssayEditors } from "./App";
import { Navbar } from "./Navbar";

export function Essay({
  upvoteData,
  setUpvoteData,
  retrieveTopEssays,
  getYourEssayUpvotes,
}) {
  //Write GET /retrieve-essay to the id

  const date = "January 2023";

  // const defaultEssayObject = {
  //   title: defaultEssayTitle,
  //   body: defaultEssay,
  //   editors: defaultEssayEditors,
  //   upvotes: 0,
  //   promptEngineer: null,
  // };

  const [essay, setEssay] = useState("");

  const { id } = useParams(); //Location state to be defined in router file

  //   const id = "recciU351b7wDZbdc";

  useEffect(() => {
    console.log(id);

    axios
      .post(`${baseUrl}/retrieve-essay`, {
        id: id,
      })
      .then((response) => {
        setEssay(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  //Destructure essay object
  const { title, body, editors, upvotes, promptEngineer } = essay;

  // Prevents page from rendering if no id (or can use record.body.x) is passed in
  if (!title || !body || !editors) {
    return <></>;
  }

  return (
    <div style={{ display: "flex", marginBottom: "16px" }}>
      <Navbar />
      <td>
        <a href="https://paulgrahamai.com/">
          <img
            src="https://s.turbifycdn.com/aah/paulgraham/responses-6.gif"
            width="410"
            height="45"
            border="0"
            hspace="0"
            vspace="0"
            alt="Paul Graham"
          />
        </a>
        <br></br>
        <table border="0" cellspacing="0" cellpadding="0" width="435">
          <tbody>
            <tr valign="top">
              <td width="435">
                <div
                  style={{
                    width: "435px",
                    overflow: "hidden",
                    textAlign: "left",
                    marginTop: "15px",
                    minHeight: "20px",
                  }}
                >
                  <ImageTitle text={title} />
                </div>
                <div></div>
                <div style={{ height: "15px" }}></div>
                <font size="2" face="verdana">
                  <div>
                    {date}
                    <div>
                      <div
                        style={{
                          display: "flex",
                          whiteSpace: "pre-wrap",
                          alignContent: "left",
                        }}
                      >
                        <p
                          style={{
                            marginTop: "16px",
                          }}
                        >
                          <GrayOutNumbers text={body} />
                        </p>
                        <br></br>
                        <br></br>
                      </div>
                      <p>
                        <a name="end"></a>
                        <span style={{ fontWeight: "600" }}>Thanks </span>
                        to {editors?.split(";")[0]}, {editors?.split(";")[1]},{" "}
                        {editors?.split(";")[2]}, and {editors?.split(";")[3]}{" "}
                        for reading drafts of this
                        {promptEngineer ? (
                          <span>
                            , and to{" "}
                            <a
                              href={`https://twitter.com/${promptEngineer}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              @{promptEngineer}
                            </a>{" "}
                            for prompt engineering it.
                          </span>
                        ) : (
                          "."
                        )}
                      </p>
                      <div style={{ display: "flex" }}>
                        {!upvoteData.find((data) => data.id === id) ||
                        upvoteData?.some((data) => data.id === id).upvote ===
                          0 ? (
                          <div style={{ display: "flex" }}>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleUpvote({
                                  id,
                                  upvoteData,
                                  setUpvoteData,
                                  retrieveTopEssays,
                                  getYourEssayUpvotes,
                                })
                              }
                            >
                              <u>Upvote this essay</u>
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleUpvote({
                                  id,
                                  upvoteData,
                                  setUpvoteData,
                                  retrieveTopEssays,
                                  getYourEssayUpvotes,
                                })
                              }
                            >
                              &nbsp;(+{upvotes})
                            </span>
                            <div
                              className="votearrow"
                              title="upvote"
                              style={{ width: "10px", height: "10px" }}
                              onClick={() =>
                                handleUpvote({
                                  id,
                                  upvoteData,
                                  setUpvoteData,
                                  retrieveTopEssays,
                                  getYourEssayUpvotes,
                                })
                              }
                            ></div>
                          </div>
                        ) : (
                          <>
                            <div style={{ display: "flex" }}>
                              <span>Essay has been upvoted!</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </font>
              </td>
            </tr>
            <br></br>
          </tbody>
        </table>
        <table border="0" cellspacing="0" cellpadding="0" width="435">
          <tbody>
            <tr>
              <td>
                <font size="2" face="verdana">
                  <br></br>
                  <hr />
                </font>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </div>
  );
}
