// import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Essay } from "./Essay";
import App from "./App";
import { Routes, Route } from "react-router-dom";
import useLocalStorage from "./hooks/useLocalStorage";
import axios from "axios";
import { useState } from "react";

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.paulgrahamai.com"
    : "http://localhost:8081";

export function Router() {
  const [upvoteData, setUpvoteData] = useLocalStorage("upvoteData", []);
  const [topEssaysArray, setTopEssaysArray] = useState([]);
  const [yourEssaysArray, setYourEssaysArray] = useLocalStorage(
    "yourEssaysArray",
    []
  );

  function retrieveTopEssays() {
    axios
      .get(`${baseUrl}/top-essays`)
      .then((response) => {
        setTopEssaysArray(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getYourEssayUpvotes() {
    await axios
      .post(`${baseUrl}/your-essay-upvotes`, {
        yourEssaysArray: yourEssaysArray,
      })
      .then((response) => {
        console.log(response.data);
        //Use setYourEssaysArray to update ONLY the upvote count for each essay in yourEssaysArray. Do not change the other properties of each essay in yourEssaysArray.
        const updatedUpvoteArray = yourEssaysArray.map((essay) => {
          const updatedItem = response.data.find(
            (essayUpvote) => essayUpvote.id === essay.id
          );
          if (updatedItem) {
            return { ...essay, upvotes: updatedItem.upvotes };
          }
          return essay;
        });
        setYourEssaysArray([...updatedUpvoteArray]);
        console.log(...updatedUpvoteArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <App
            upvoteData={upvoteData}
            setUpvoteData={setUpvoteData}
            retrieveTopEssays={retrieveTopEssays}
            topEssaysArray={topEssaysArray}
            setTopEssaysArray={setTopEssaysArray}
            yourEssaysArray={yourEssaysArray}
            setYourEssaysArray={setYourEssaysArray}
            getYourEssayUpvotes={getYourEssayUpvotes}
          />
        }
      ></Route>

      <Route
        path="/:id"
        element={
          <Essay
            upvoteData={upvoteData}
            setUpvoteData={setUpvoteData}
            retrieveTopEssays={retrieveTopEssays}
            getYourEssayUpvotes={getYourEssayUpvotes}
          />
        }
      />
      <Route
        path="*"
        element={
          <App
            upvoteData={upvoteData}
            setUpvoteData={setUpvoteData}
            retrieveTopEssays={retrieveTopEssays}
            topEssaysArray={topEssaysArray}
            setTopEssaysArray={setTopEssaysArray}
            yourEssaysArray={yourEssaysArray}
            setYourEssaysArray={setYourEssaysArray}
            getYourEssayUpvotes={getYourEssayUpvotes}
          />
        }
      />
    </Routes>
  );
}
