import DOMPurify from "dompurify";

export function GrayOutNumbers({ text }) {
  let numberMatches = text.match(/\[\d+\]/g);
  let notesMatches = text.match("Notes");

  let grayText = text;
  if (numberMatches || notesMatches) {
    numberMatches.forEach((match) => {
      grayText = grayText.replace(
        match,
        `<span style='color: #dddddd;'><a href='#end' style='color: #dddddd'>${match}</a></span>`
      );
    });

    notesMatches?.forEach((match) => {
      grayText = grayText.replace(match, `<span><b>${match}</b></span>`);
    });
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(grayText) }} />
  );
}
