import React, { useState, useEffect } from "react";

export function ImageTitle({ text }) {
  const [img, setImg] = useState(null);

  useEffect(() => {
    const imgSrc = generateImg(text);
    setImg(imgSrc);
    console.log(text);
  }, [text]);

  function generateImg(text) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 538;
    canvas.height = 38;
    ctx.font = "small-caps 18px arial";
    ctx.fillStyle = "#6e1414";
    ctx.textAlign = "left";
    ctx.textBaseline = "bottom";
    ctx.fillText(text, 0, canvas.height / 2);
    const imgSrc = canvas.toDataURL();
    console.log("image render");
    return imgSrc;
  }

  return (
    <img
      src={img}
      alt={text}
      style={{
        width: "468px",
        height: "36px",
        margin: 0,
        padding: 0,
        border: 0,
        position: "absolute",
      }}
    />
  );
}
