import axios from "axios";
import { baseUrl } from "./routing";
import Plausible from "plausible-tracker";

const { trackEvent } = Plausible();

export async function handleUpvote({
  id,
  upvoteData,
  setUpvoteData,
  retrieveTopEssays,
  getYourEssayUpvotes,
}) {
  setUpvoteData([...upvoteData, { id: id, upvote: 1 }]);
  trackEvent("Upvote");
  await axios
    .post(`${baseUrl}/upvote`, {
      id: id,
    })
    .then((response) => {
      console.log(response.data);
      retrieveTopEssays();
      getYourEssayUpvotes();
    })
    .catch((error) => {
      console.log(error);
    });
}
