import React from "react";
import DEFAULT_TEMPERATURE from "./App";

export function Button({ displayText, promptText, handleGenerate }) {
  const promptEngineer = "";

  return (
    <button
      style={{
        width: "146px",
        color: "white",
        backgroundColor: "#666699",
        borderColor: "#CCCCCC #333333 #333333 #999999",
        borderWidth: "3px",
        fontSize: "0.8em",
        fontFamily: "verdana",
        textShadow: "#333333 -1px -1px",
        padding: "2px 0px 2px 0px",
        margin: "0px",
        cursor: "pointer",
      }}
      onClick={() => {
        console.log(promptText);
        handleGenerate(promptText, promptEngineer, 0.5);
      }}
    >
      {displayText}
    </button>
  );
}
